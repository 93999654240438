import React, { FC } from "react";
import "./styles.scss";
import { MemberCoordinacion } from "../../data/types";
import { nanoid } from "nanoid";

interface iProps {
  className?: string;
  members: MemberCoordinacion[];
}

const Coordinacion: FC<iProps> = ({ className = "", members }) => (
  <section className={`coordinacion ${className}`}>
    {members.map((member: MemberCoordinacion) => (
      <div className="member" key={nanoid()}>
        <div className="member-picture">
          <img
            src={member.foto.sourceUrl}
            srcSet={member.foto.srcSet}
            alt={member.foto.altText}
          />
        </div>
        <div className="member-bio">
          <div className="member-name">{member.nombre}</div>
          <p className="member-cabecera">{member.cabecera}</p>
          <p className="member-parrafo">{member.parrafo}</p>
        </div>
      </div>
    ))}
  </section>
);

export default Coordinacion;
