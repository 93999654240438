import React, { FC } from "react";
import "./styles.scss";

const HamburgerIcon: FC<{ onClick: () => void; open: boolean }> = ({
  onClick,
  open,
}) => {
  const onToggle = () => {
    onClick();
  };

  return (
    <div className={`hamburger ${open ? "is-active" : ""}`} onClick={onToggle}>
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  );
};

export default HamburgerIcon;
