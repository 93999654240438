import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Link, useParams } from "react-router-dom";
import "./styles.scss";
import HeroProject from "../../shared/hero-project";
import Button from "../../shared/button";
import parse from "html-react-parser";
import Spinner from "../../shared/spinner";

const HacemosSeccion = (props: any) => {
  let { section } = useParams<any>();
  const hacemosSection = props.data.pages
    ? props.data.pages.nodes[0].hacemos_section.seccion.filter(
        (project: any) => {
          return project?.titulo?.slug === section;
        }
      )[0]
    : null;

  const hacemosSectionProjects = props?.data?.proyectos?.edges
    ? props?.data?.proyectos.edges.filter((project: any) => {
        return project.node?.categories?.nodes
          ?.map((cat: any) => cat.slug)
          ?.some((cat: any) => cat.toLowerCase() === section?.toLowerCase());
      })
    : null;

  const {
    data: { loading },
  } = props;
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className="hacemos-section-wrapper">
      <HeroProject content={hacemosSection} />
      <div className="hacemos-content">
        <div className="hacemos-section-details">
          <div className="hero-text">
            <h1 className="hero-hacemos-heading">
              {hacemosSection?.titulo.name}
            </h1>
            {hacemosSection?.parrafo && (
              <p className="parrafo-principal">
                {parse(hacemosSection?.parrafo)}
              </p>
            )}
            {hacemosSection?.parrafoSecundario && (
              <p className="parrafo-secundario">
                {parse(hacemosSection?.parrafoSecundario)}
              </p>
            )}
          </div>
          <div className="map">
            <img
              src={hacemosSection?.mapaCabeceraSeccionpais?.mediaItemUrl}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="hacemos-projects">
        {hacemosSectionProjects?.map((project: any) => {
          return (
            <div className="hacemos-project" key={project.node.slug}>
              <div className="project-image">
                <img
                  src={
                    project.node.detalles?.imagenHacemosSeccion?.mediaItemUrl
                  }
                  srcSet={project.node.detalles?.imageHacemosSeccion?.srcSet}
                  alt={project.node.detalles?.imagenHacemosSeccion?.altText}
                />
              </div>
              <div className="project-details">
                <p className="title">{project.node.detalles.textoCabecera}</p>
                <p className="tags">
                  {project.node.tags?.nodes.length > 0 && (
                    <hr className="small-separator" />
                  )}
                  {project.node.tags?.nodes.map((tag: any) => {
                    return (
                      <Link to={`/hacemos/etiquetas/${tag.slug}`}>
                        <span key={tag.name}>{tag.name} </span>
                      </Link>
                    );
                    // return <span key={tag.name}>{tag.name} </span>;
                  })}
                </p>
                <Link
                  className="ver-proyecto-button"
                  to={`/hacemos/proyectos/${project.node.slug}`}
                >
                  <Button size="medium" type="secondary">
                    Ver proyecto
                  </Button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default graphql(gql`
  query getHacemosSeccion {
    pages(where: { id: 37 }) {
      nodes {
        hacemos_section {
          seccion {
            titulo {
              slug
              name
            }
            parrafo
            imagen {
              altText
              mediaItemUrl
              srcSet
            }
            parrafoSecundario
            mapaCabeceraSeccionpais {
              mediaItemUrl
            }
            imagenCabeceraDeSeccionpais {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
        }
      }
    }
    proyectos(first: 100) {
      edges {
        node {
          title
          detalles {
            fecha
            imagenCabecera {
              srcSet
              mediaItemUrl
              altText
            }
            imagenHacemosSeccion {
              altText
              mediaItemUrl
              srcSet
            }
            pais
            textoCabecera
          }
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          tags(first: 100) {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`)(HacemosSeccion);
