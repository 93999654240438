import React, { FC } from "react";
import "./styles.scss";

const Spinner: FC = () => {
  return (
    <div className="spinneroverlay donatespinner">
      <div className="loadingcontent">
        <div className="spinner"></div>
        <div className="text-center">
          <p id="spinnerText"></p>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
