import React, { FC, useState, useEffect } from "react";
import MainHeading from "../shared/main-heading";
import MainHeadingTop from "../shared/main-heading-top";
import { nanoid } from "nanoid";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import "./styles.scss";
import { SliderNextArrow, SliderPrevArrow } from "../shared/modal-gallery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const DondeTrabajamos: FC = (props: any) => {
  const categories = props.data.categories
    ? props.data.categories.nodes
        .sort((a: any, b: any) => Number(b.count) - Number(a.count))
        .filter(
          (cat: any) =>
            cat.categoryId !== 30 &&
            cat.categoryId !== 31 &&
            cat.categoryId !== 32
        )
    : null;

  const hacemos = props.data.pages
    ? props.data.pages.nodes[0].hacemos_section.seccion
    : null;

  const projects = props.data.proyectos ? props.data.proyectos.nodes : null;

  const [category, setCategory] = useState(categories?.[0].name);

  useEffect(() => {
    if (categories && !category) {
      setCategory(categories[0].name);
    }
  }, [categories, category]);

  const updateCategory = (cat: string) => {
    setCategory(cat);
  };

  const sliderSettings = {
    dots: false,
    infinite:
      projects?.filter(
        (project: any) => project.categories?.edges[0]?.node?.name === category
      )?.length > 2,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    speed: 200,
    cssEase: "linear",
    // centerMode: true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centerMode: false,
    //     },
    //   },
    // ],
  };

  return (
    <section
      className={`donde-trabajamos ${
        projects?.filter(
          (project: any) =>
            project.categories?.edges[0]?.node?.name === category
        ).length > 1
          ? ""
          : "singe-item"
      }`}
    >
      <MainHeadingTop align="center">CONOCE</MainHeadingTop>
      <MainHeading align="center">Dónde trabajamos</MainHeading>
      <div className="desktop">
        <nav>
          <ul className="donde-trabajamos-nav">
            {categories?.map((cat: any) => {
              return (
                <li
                  key={nanoid()}
                  onClick={() => updateCategory(cat.name)}
                  className={`${cat.name === category ? "active" : ""}`}
                >
                  {cat.name}
                </li>
              );
            })}
          </ul>
        </nav>
        <Slider {...sliderSettings} className="donde-trabajamos-slider">
          {projects
            ?.filter(
              (project: any) =>
                project.categories?.edges
                  .map((edge: any) => edge.node.name)
                  .some((name: string) => name === category)
              // project.categories?.edges[0]?.node?.name === category &&
              // project.categories?.edges[0]?.node?.proyectoId !== 712 &&
              // project.categories?.edges[0]?.node?.proyectoId !== 711 &&
              // project.categories?.edges[0]?.node?.proyectoId !== 713
            )
            .map((project: any) => {
              return (
                <div key={nanoid()} className="donde-trabajamos-project">
                  <Link to={`/hacemos/proyectos/${project?.slug}`}>
                    <img
                      className="project-image"
                      src={project?.detalles?.imagenCabecera?.mediaItemUrl}
                      alt={project?.detalles?.imagenCabecera?.altText}
                    />
                    <div className="details">
                      <h4 className="heading">{project?.title}</h4>
                      {/* <h4 className="heading">{project?.detalles?.pais}</h4> */}
                      <p className="paragraph">
                        {project?.detalles?.textoCabecera}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
        </Slider>
      </div>
      <div className="mobile">
        <div className="donde-trabajamos-secciones">
          {categories?.map((cat: any) => {
            return (
              <Link to={`/hacemos/seccion/${cat?.slug}`} key={nanoid()}>
                <img
                  src={
                    hacemos?.filter(
                      (seccion: any) => seccion.titulo.slug === cat.slug
                    )[0].imagen.mediaItemurl
                  }
                  srcSet={
                    hacemos?.filter(
                      (seccion: any) => seccion.titulo.slug === cat.slug
                    )[0].imagen.srcSet
                  }
                  alt=""
                />
                <span>{cat?.name}</span>
              </Link>
            );
          })}
          {/* {hacemos
            ?.filter((seccion: any) =>
              categories
                .map((cat: any) => cat.name)
                .includes(seccion.titulo.name)
            )
            // .sort(function (a: any, b: any) {
            //   return categories.indexOf(a.name) - categories.indexOf(b.name);
            // })
            ?.map((seccion: any) => {
              return (
                <Link
                  to={`/hacemos/seccion/${seccion?.titulo?.slug}`}
                  key={nanoid()}
                >
                  <img
                    src={seccion?.imagen?.mediaItemurl}
                    srcSet={seccion?.imagen?.srcSet}
                    alt=""
                  />
                  <span>{seccion?.titulo?.name}</span>
                </Link>
              );
            })} */}
        </div>
      </div>
    </section>
  );
};

export default graphql(gql`
  query DondeTrabajamos {
    pages(where: { id: 37 }) {
      nodes {
        hacemos_section {
          seccion {
            titulo {
              slug
              name
            }
            parrafo
            imagen {
              altText
              mediaItemUrl
              srcSet
            }
            parrafoSecundario
            mapaCabeceraSeccionpais {
              mediaItemUrl
            }
            imagenCabeceraDeSeccionpais {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
        }
      }
    }
    categories {
      nodes {
        name
        slug
        count
        categoryId
      }
    }
    proyectos(first: 100) {
      nodes {
        detalles {
          pais
          textoCabecera
          imagenCabecera {
            mediaItemUrl
            altText
          }
        }
        title
        slug
        proyectoId
        categories {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
`)(DondeTrabajamos);
