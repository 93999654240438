import React, { FC } from "react";
import "./styles.scss";

interface iProps {
  className?: string;
  content: any;
}

const Hero: FC<iProps> = ({ className = "", content }) => {
  return (
    <div className={`hero ${className}`}>
      <div className="gradient"></div>
      <img
        srcSet={content?.imagen?.srcSet}
        src={content?.imagen?.srcUrl}
        alt={content?.imagen?.altText}
      />
      <div className="hero-text">
        {content?.titulo && <h1>{content?.titulo}</h1>}
        {content?.parrafo && <p>{content?.parrafo}</p>}
      </div>
    </div>
  );
};

export default Hero;
