import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import "./styles.scss";
import Hero from "../shared/hero";
import MainContent from "../shared/main-content";
import Button from "../shared/button";
import Spinner from "../shared/spinner";
import parse from "html-react-parser";

const Hacemos = (props: any) => {
  const {
    data: { loading },
  } = props;
  const proyectos = props.data.pages
    ? props.data.pages.nodes[0].hacemos_section.seccion
    : null;
  const cabecera = props.data.pages
    ? props.data.pages?.nodes?.[0]?.cabecera
    : null;
  const pageContent = props.data.pages
    ? props.data.pages.nodes[0].pagina
    : null;

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Hero content={cabecera?.cabecera} />
      {proyectos
        ? proyectos.map((project: any) => {
            return (
              <section className="hacemos-section">
                <div className="hacemos-section-info">
                  <div className="info-content">
                    <h2 className="hacemos-section-cabecera">
                      {project?.titulo?.name}
                    </h2>
                    <p>{parse(project?.parrafo)}</p>
                    <Link to={`/hacemos/seccion/${project?.titulo?.slug}`}>
                      <Button size="medium" type="tertiary">
                        Conoce todos los proyectos
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="hacemos-section-image">
                  <Link
                    to={`/hacemos/seccion/${project?.titulo?.slug}`}
                    title="Click para ver proyecto"
                  >
                    <img
                      src={project?.imagen?.mediaItemUrl}
                      srcSet={project?.imagen?.srcSet}
                      alt={project?.imagen?.altText || project?.cabecera}
                    />
                  </Link>
                </div>
              </section>
            );
          })
        : ""}
      <main className="main-content">
        <MainContent content={pageContent?.contenido} />
      </main>
    </>
  );
};

export default graphql(gql`
  query getHacemos {
    pages(where: { id: 37 }) {
      nodes {
        cabecera {
          cabecera {
            titulo
            parrafo
            imagen {
              altText
              sourceUrl
              srcSet
            }
          }
        }
        pagina {
          contenido {
            ... on Page_Pagina_Contenido_Texto {
              fieldGroupName
              texto
            }
            ... on Page_Pagina_Contenido_TextoEnColumnas {
              fieldGroupName
              columnas {
                ... on Page_Pagina_Contenido_TextoEnColumnas_Columnas_Columna {
                  fieldGroupName
                  textoColumna1
                  textoColumna2
                }
              }
            }
            ... on Page_Pagina_Contenido_Cabecera {
              fieldGroupName
              textoCabecera
            }
            ... on Page_Pagina_Contenido_TituloCabecera {
              fieldGroupName
              textoTituloCabecera
            }
            ... on Page_Pagina_Contenido_Separador {
              fieldGroupName
              separador
            }
            ... on Page_Pagina_Contenido_Recortes {
              fieldGroupName
              recorte {
                ... on Page_Pagina_Contenido_Recortes_Recorte_Contenido {
                  cabecera
                  fieldGroupName
                  parrafo
                  icono {
                    altText
                    srcSet
                    sourceUrl
                  }
                }
              }
            }
            ... on Page_Pagina_Contenido_Boton {
              fieldGroupName
              tipoDeBoton
              texto
              link {
                ... on Page {
                  id
                  slug
                }
                ... on Proyecto {
                  id
                  slug
                }
              }
              linkToContact
            }
            ... on Page_Pagina_Contenido_Coordinacion {
              fieldGroupName
              miembroDelEquipo {
                cabecera
                nombre
                parrafo
                foto {
                  altText
                  srcSet
                  sourceUrl
                }
              }
            }
            ... on Page_Pagina_Contenido_JuntaDirectiva {
              fieldGroupName
              miembrosDeLaJuntaDirectiva {
                nombre
                parrafo
                puesto
              }
            }
            ... on Page_Pagina_Contenido_SociosLocales {
              fieldGroupName
              socios {
                pais
                enlaces {
                  enlace
                  texto
                }
              }
              parrafo
            }
            ... on Page_Pagina_Contenido_Transparencias {
              fieldGroupName
              contenido {
                ... on Page_Pagina_Contenido_Transparencias_Contenido_Descarga {
                  fieldGroupName
                  texto
                  archivo {
                    altText
                    mediaItemUrl
                  }
                }
                ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon {
                  cabecera
                  fieldGroupName
                  contenido {
                    ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon_Contenido_Descarga {
                      fieldGroupName
                      texto
                      archivo {
                        altText
                        mediaItemUrl
                      }
                    }
                    ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon_Contenido_GrupoDescargas {
                      fieldGroupName
                      titulo
                      archivos {
                        fieldGroupName
                        texto
                        archivo {
                          altText
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        hacemos_section {
          seccion {
            parrafo
            imagen {
              altText
              mediaItemUrl
              srcSet
            }
            titulo {
              slug
              name
            }
          }
        }
      }
    }
  }
`)(Hacemos);
