import React, { FC, ReactChild, useState } from "react";

import "./styles.scss";

interface iProps {
  children: ReactChild[] | ReactChild;
  className?: string;
}

const Accordion: FC<iProps> = ({ children, className = "" }) => {
  const [activeItem, setActiveItem] = useState(null);
  const onClickItem = (item: any) => {
    if (activeItem === item) {
      setActiveItem(null);
    } else {
      setActiveItem(item);
    }
  };
  const props = { onClickItem, activeItem };
  return (
    <div className={`accordion-wrapper ${className}`}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, props);
        }
        return child;
      })}
    </div>
  );
};
export default Accordion;
