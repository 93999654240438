import React, { FC, ReactChild } from "react";
import "./styles.scss";

interface iProps {
  children: ReactChild;
  className?: string;
  align?: "left" | "center" | "right";
}

const MainHeading: FC<iProps> = ({
  children,
  className = "",
  align = "left",
}) => <h2 className={`main-heading ${className} ${align}`}>{children}</h2>;

export default MainHeading;
