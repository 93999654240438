import React, { FC, ReactChild, useRef, useState, useEffect } from "react";
import { ReactComponent as Chevron } from "../../../../assets/chevron.svg";

import "./accordion-item.scss";

interface iProps {
  children: ReactChild;
  className?: string;
  buttonText: string;
  onClickItem?: (item: any) => {};
  activeItem?: any;
}

const AccordionItem: FC<iProps> = ({
  children,
  className = "",
  buttonText,
  onClickItem,
  activeItem,
}) => {
  const accordionItemRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(activeItem === accordionItemRef.current);

  useEffect(() => {
    setOpen(activeItem === accordionItemRef.current);
  }, [activeItem]);

  const onToggle = () => {
    if (onClickItem) {
      onClickItem(accordionItemRef.current);
    }
  };

  // const onClose = () => {
  //   if (onClickItem) {
  //     onClickItem(null);
  //   }
  // };

  return (
    <>
      <div
        className={`accordion-item ${open ? "active" : ""} ${className}`}
        onClick={onToggle}
        ref={accordionItemRef}
      >
        {buttonText} <Chevron />
      </div>
      <div className={`panel ${open ? "open" : ""}`}>
        {children}

        {/* <div
          className={`accordion-item ${open ? "active" : ""} ${className}`}
          onClick={onClose}
          ref={accordionItemRef}
        >
          {buttonText} <Chevron />
        </div> */}
      </div>
    </>
  );
};

export default AccordionItem;
