import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import MainContent from "../shared/main-content";
import "./styles.scss";

const PoliticaPrivacidad = (props: any) => {
  const pageContent = props.data.pages
    ? props.data.pages.nodes[0].pagina
    : null;

  return (
    <section className="privacidad">
      <main>
        <MainContent content={pageContent?.contenido} />
      </main>
    </section>
  );
};

export default graphql(gql`
  query getPoliticaPrivacidad {
    pages(where: { id: 429 }) {
      nodes {
        pagina {
          contenido {
            ... on Page_Pagina_Contenido_Texto {
              fieldGroupName
              texto
            }
            ... on Page_Pagina_Contenido_TextoEnColumnas {
              fieldGroupName
              columnas {
                ... on Page_Pagina_Contenido_TextoEnColumnas_Columnas_Columna {
                  fieldGroupName
                  textoColumna1
                  textoColumna2
                }
              }
            }
            ... on Page_Pagina_Contenido_Cabecera {
              fieldGroupName
              textoCabecera
            }
            ... on Page_Pagina_Contenido_TituloCabecera {
              fieldGroupName
              textoTituloCabecera
            }
            ... on Page_Pagina_Contenido_Separador {
              fieldGroupName
              separador
            }
            ... on Page_Pagina_Contenido_Recortes {
              fieldGroupName
              recorte {
                ... on Page_Pagina_Contenido_Recortes_Recorte_Contenido {
                  cabecera
                  fieldGroupName
                  parrafo
                  icono {
                    altText
                    srcSet
                    sourceUrl
                  }
                }
              }
            }
            ... on Page_Pagina_Contenido_Boton {
              fieldGroupName
              texto
              link {
                ... on Page {
                  id
                  slug
                }
                ... on Proyecto {
                  id
                  slug
                }
              }
            }
            ... on Page_Pagina_Contenido_Coordinacion {
              fieldGroupName
              miembroDelEquipo {
                cabecera
                nombre
                parrafo
                foto {
                  altText
                  srcSet
                  sourceUrl
                }
              }
            }
            ... on Page_Pagina_Contenido_JuntaDirectiva {
              fieldGroupName
              miembrosDeLaJuntaDirectiva {
                nombre
                parrafo
                puesto
              }
            }
            ... on Page_Pagina_Contenido_SociosLocales {
              fieldGroupName
              socios {
                pais
                enlaces {
                  enlace
                  texto
                }
              }
              parrafo
            }
            ... on Page_Pagina_Contenido_Transparencias {
              fieldGroupName
              contenido {
                ... on Page_Pagina_Contenido_Transparencias_Contenido_Descarga {
                  fieldGroupName
                  texto
                  archivo {
                    altText
                    mediaItemUrl
                  }
                }
                ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon {
                  cabecera
                  fieldGroupName
                  contenido {
                    ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon_Contenido_Descarga {
                      fieldGroupName
                      texto
                      archivo {
                        altText
                        mediaItemUrl
                      }
                    }
                    ... on Page_Pagina_Contenido_Transparencias_Contenido_Acordeon_Contenido_GrupoDescargas {
                      fieldGroupName
                      titulo
                      archivos {
                        fieldGroupName
                        texto
                        archivo {
                          altText
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)(PoliticaPrivacidad);
