import React, { FC } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { nanoid } from "nanoid";
import Snippets from "../snippets";
import MainHeading from "../main-heading";
import MainHeadingTop from "../main-heading-top";
import Separator from "../separator";
import Button from "../button";
import "./styles.scss";
import Coordinacion from "../../coordinacion";
import JuntaDirectiva from "../../junta-directiva";
import Accordion from "../accordion";
import AccordionItem from "../accordion/accordion-item/accordion-item";
import SociosLocales from "../../socios-locales";
import Spinner from "../spinner";

interface iProps {
  className?: string;
  content: any;
}

const MainContent: FC<iProps & any> = (props) => {
  const { className = "", content } = props;

  if (!content) {
    return <Spinner />;
  }

  return (
    <div className={`main-content ${className}`}>
      {content?.map((content: any) => {
        if (content.fieldGroupName === "page_Pagina_Contenido_TituloCabecera") {
          return (
            <section key={nanoid()}>
              <MainHeadingTop key={nanoid()} align="center">
                {content.textoTituloCabecera}
              </MainHeadingTop>
            </section>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_Cabecera"
        ) {
          return (
            <section key={nanoid()}>
              <MainHeading align="center" key={nanoid()}>
                {content.textoCabecera}
              </MainHeading>
            </section>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_Separador"
        ) {
          return <Separator key={nanoid()} />;
        } else if (content.fieldGroupName === "page_Pagina_Contenido_Texto") {
          return (
            <section className="text-container" key={nanoid()}>
              <div key={nanoid()}>{parse(content.texto)}</div>
            </section>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_TextoEnColumnas"
        ) {
          return (
            <section key={nanoid()}>
              <div className="columns-container">
                {content.columnas.map((col: any) => {
                  return (
                    <div key={col.textoColumna1}>
                      <div className="column">{parse(col.textoColumna1)}</div>
                      <div className="column">{parse(col.textoColumna2)}</div>
                    </div>
                  );
                })}
              </div>
            </section>
          );
          //
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_Recortes"
        ) {
          return (
            <section key={nanoid()}>
              <Snippets snippets={content.recorte} />
            </section>
          );
        } else if (content.fieldGroupName === "page_Pagina_Contenido_Boton") {
          if (content.tipoDeBoton === "Sección") {
            return (
              <Link key={nanoid()} to={content.link.slug} className="link">
                <Button size="medium" className="centered">
                  {`${content.texto ? content.texto : ""}`}
                </Button>
              </Link>
            );
          } else {
            return (
              <a key={nanoid()} href={content.linkToContact} className="link">
                <Button size="medium" className="centered">
                  {`${content.texto ? content.texto : ""}`}
                </Button>
              </a>
            );
          }
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_Coordinacion"
        ) {
          return (
            <Accordion key={nanoid()} className="accordion-coordinacion">
              <AccordionItem buttonText="mostrar equipo de coordinacion">
                <Coordinacion members={content.miembroDelEquipo} />
              </AccordionItem>
            </Accordion>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_JuntaDirectiva"
        ) {
          return (
            <Accordion key={nanoid()}>
              <AccordionItem buttonText="mostrar directiva">
                <JuntaDirectiva members={content.miembrosDeLaJuntaDirectiva} />
              </AccordionItem>
            </Accordion>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_SociosLocales"
        ) {
          return (
            <Accordion key={nanoid()}>
              <AccordionItem buttonText="mostrar organizaciones">
                <SociosLocales
                  sociosLocales={content.socios}
                  paragraph={content.parrafo}
                />
              </AccordionItem>
            </Accordion>
          );
        } else if (
          content.fieldGroupName === "page_Pagina_Contenido_Transparencias"
        ) {
          return (
            <section className="transparencia">
              <section key={nanoid()}>
                <MainHeadingTop align="center">
                  documentaci&oacute;n institucional
                </MainHeadingTop>
                <MainHeading align="center">Transparencia</MainHeading>
              </section>
              {content.contenido.map((content: any) => {
                if (
                  content.fieldGroupName ===
                  "page_Pagina_Contenido_Transparencias_Contenido_Descarga"
                ) {
                  return (
                    <div key={nanoid()} className="single-download">
                      <div className="titulo">{content.texto}</div>
                      <div className="archivo">
                        <a
                          href={content.archivo.mediaItemUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Descargar
                        </a>
                      </div>
                    </div>
                  );
                } else if (
                  content.fieldGroupName ===
                  "page_Pagina_Contenido_Transparencias_Contenido_Acordeon"
                ) {
                  return (
                    <Accordion>
                      <AccordionItem buttonText={content.cabecera}>
                        {content.contenido.map((item: any) => {
                          return (
                            <div key={nanoid()}>
                              {item.titulo && (
                                <div className="accordion-list-title">
                                  {item.titulo}
                                </div>
                              )}
                              <ul className="accordion-list">
                                {item.archivos ? (
                                  item.archivos.map((item: any) => {
                                    return (
                                      <li>
                                        <a
                                          href={item.archivo.mediaItemUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {item.texto}
                                        </a>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <li className="accordion-list-item">
                                    <div>{item.texto}</div>

                                    <a
                                      href={item.archivo.mediaItemUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Descargar
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          );
                        })}
                      </AccordionItem>
                    </Accordion>
                  );
                }
                return null;
              })}
            </section>
          );
        }
      })}
    </div>
  );
};

export default MainContent;
