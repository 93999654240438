import React, { FC } from "react";
import "./styles.scss";
import { MemberJuntaDirectiva } from "../../data/types";

interface iProps {
  className?: string;
  members: MemberJuntaDirectiva[];
}

const JuntaDirectiva: FC<iProps> = ({ className = "", members }) => (
  <section className={`junta-directiva ${className}`}>
    {members.map((member: MemberJuntaDirectiva) => (
      <div className="member">
        <div className="member-bio">
          <div className="member-name">{member.nombre}</div>
          <p className="member-cabecera">{member.puesto}</p>
          <p className="member-parrafo">{member.parrafo}</p>
        </div>
      </div>
    ))}
  </section>
);

export default JuntaDirectiva;
