import React, { FC } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import ReactPlayer from "react-player";
import "./styles.scss";
import Button from "../shared/button";

const Materiales: FC = (props: any) => {
  console.log("materiales: ", { props });
  const materiales = props?.data?.pages?.nodes?.[0]?.materiales
    ? props?.data?.pages?.nodes?.[0]?.materiales
    : null;
  const { cookiesAccepted, onClickCookieOptions } = props;

  return (
    <div className="materiales-section">
      <h1 className="main-heading">{materiales?.cabecera}</h1>
      {materiales?.seccion.map((seccion: any) => {
        return (
          <>
            <div className="materiales-seccion">
              <div>
                {seccion.contenido[0].fieldGroupName ===
                  "page_Materiales_seccion_Contenido_Video" &&
                  !cookiesAccepted && (
                    <div className="video">
                      Necesitas aceptar las cookies para poder ver nuestros
                      videos.{" "}
                      <Button onClick={() => onClickCookieOptions(true)}>
                        Aceptar cookies
                      </Button>
                    </div>
                  )}
              </div>
              <section>
                {(seccion.contenido[0].fieldGroupName !==
                  "page_Materiales_seccion_Contenido_Video" ||
                  (seccion.contenido[0].fieldGroupName ===
                    "page_Materiales_seccion_Contenido_Video" &&
                    cookiesAccepted)) && (
                  <div className="sidebar">
                    {seccion.titulo && (
                      <h3 className="title">{seccion.titulo}</h3>
                    )}
                    {seccion.cabecera && (
                      <h2 className="heading">{seccion.cabecera}</h2>
                    )}
                    {seccion.parrafo && (
                      <p className="paragraph">{seccion.parrafo}</p>
                    )}
                  </div>
                )}

                <div className="content">
                  {seccion.contenido.map((item: any) => {
                    if (
                      item.fieldGroupName ===
                      "page_Materiales_seccion_Contenido_Imagen"
                    ) {
                      return (
                        <a
                          className="imagen"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href={item.documento.mediaItemUrl}
                        >
                          <img
                            src={item.imagen?.mediaItemUrl}
                            alt={item.imagen?.altText}
                            srcSet={item.imagen?.srcSet}
                          />
                        </a>
                      );
                    } else if (
                      item.fieldGroupName ===
                      "page_Materiales_seccion_Contenido_ImagenConTexto"
                    ) {
                      return (
                        <a
                          className="imagen_texto"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href={item.imagenConTexto?.documento?.mediaItemUrl}
                        >
                          <img
                            src={item.imagenConTexto.imagen?.mediaItemUrl}
                            alt={item.imagenConTexto.imagen?.altText}
                            srcSet={item.imagenConTexto.imagen?.srcSet}
                          />
                          <p>{item.imagenConTexto.parrafo}</p>
                        </a>
                      );
                    } else if (
                      item.fieldGroupName ===
                        "page_Materiales_seccion_Contenido_Video" &&
                      cookiesAccepted
                    ) {
                      return (
                        <div className="video" key={item.campoDeVideo.titulo}>
                          <h4 className="video-title">
                            {item.campoDeVideo.titulo}
                          </h4>
                          <div className="video-wrapper">
                            <ReactPlayer
                              controls
                              url={item.campoDeVideo.video}
                            />
                          </div>
                        </div>
                      );
                    }
                    // else if (
                    //   item.fieldGroupName ===
                    //     "page_Materiales_seccion_Contenido_Video" &&
                    //   !cookiesAccepted
                    // ) {
                    //     return (
                    //       <div className="video">
                    //         Necesitas aceptar las cookies para poder ver los
                    //         videos.
                    //         <Button>Aceptar cookies</Button>
                    //       </div>
                    //     );
                    // }
                    else if (
                      item.fieldGroupName ===
                      "page_Materiales_seccion_Contenido_Enlace"
                    ) {
                      return (
                        <a
                          className="enlace"
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          {item.texto}
                        </a>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
              </section>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default graphql(gql`
  query getMateriales {
    pages(where: { id: 268 }) {
      nodes {
        materiales {
          cabecera
          seccion {
            cabecera
            titulo
            parrafo
            contenido {
              ... on Page_Materiales_seccion_Contenido_Imagen {
                imagen {
                  mediaItemUrl
                  sourceUrl
                  srcSet
                  altText
                }
                fieldGroupName
                documento {
                  altText
                  mediaItemUrl
                }
              }
              ... on Page_Materiales_seccion_Contenido_ImagenConTexto {
                imagenConTexto {
                  parrafo
                  imagen {
                    altText
                    srcSet
                    sourceUrl
                  }
                  documento {
                    altText
                    mediaItemUrl
                  }
                }
                fieldGroupName
              }
              ... on Page_Materiales_seccion_Contenido_Video {
                fieldGroupName
                campoDeVideo {
                  titulo
                  video
                }
              }
              ... on Page_Materiales_seccion_Contenido_Enlace {
                texto
                url
                fieldGroupName
              }
            }
          }
        }
      }
    }
  }
`)(Materiales);
