import React, { useState, useEffect, FC } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/icid-logo-small.png";
import { ReactComponent as FacebookLogo } from "../../assets/facebook-circular-logo.svg";
import { ReactComponent as TwitterLogo } from "../../assets/twitter.svg";
import { ReactComponent as Envelope } from "../../assets/icon-mail.svg";
import Button from "../shared/button";
import "./styles.scss";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

interface Socios {
  logoPatrocinador: {
    mediaItemUrl: string;
    altText: string;
  };
  tipoDeSocio: string;
}

const Footer: FC<Socios & any> = (props: any) => {
  const { location } = props;
  const socios: any = props?.data?.patrocinadores?.nodes;

  const [sections, setSections] = useState<string[]>(["main_footer"]);
  useEffect(() => {
    if (location.pathname === "/inicio") {
      setSections(["main_footer", "colabora"]);
    }
    if (location.pathname === "/somos") {
      setSections(["main_footer", "colabora", "sponsors"]);
    }
    if (location.pathname === "/hacemos") {
      setSections(["main_footer"]);
    }
    if (location.pathname === "/servicios") {
      setSections(["main_footer", "contacta"]);
    }
    if (location.pathname === "/materiales") {
      setSections(["main_footer", "colabora"]);
    }
    if (location.pathname === "/colabora") {
      setSections(["main_footer", "contacta"]);
    }
  }, [location]);

  return (
    <div className="footer">
      {sections.includes("contacta") && <Contacta />}
      {sections.includes("sponsors") && <Sponsors socios={socios} />}
      {sections.includes("colabora") && <Colabora />}
      {sections.includes("main_footer") && <MainFooter />}
    </div>
  );
};

export default graphql(gql`
  query getSocios {
    patrocinadores(first: 100) {
      nodes {
        patrocinadores {
          tipoDeSocio
          logoPatrocinador {
            mediaItemUrl
          }
        }
      }
    }
  }
`)(withRouter(Footer));

const MainFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="main-footer">
      <div className="details">
        <a href="mailto:icid@asociacionicid.org">Contacto</a>
        <Link to={"/politica-privacidad"}>
          Politica de privacidad y cookies
        </Link>
        <span>ICID &copy; {year}</span>
      </div>
      <div className="logo">
        <img src={logo} alt="logo icid" />
        <span className="logo-text">
          Iniciativas de Cooperación Internacional para el Desarrollo
        </span>
      </div>
      <div className="social">
        <span>Siguenos en:</span>
        <div className="icons">
          <a
            href="https://www.facebook.com/AsociacionIcid"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookLogo />
          </a>
          <a
            href="https://twitter.com/AsociacionICID"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

const Colabora = () => {
  return (
    <div className="colabora-footer">
      <div className="copy">
        <div className="heading">Colabora</div>
        <div className="paragraph">
          ¡Apoya el trabajo que realizamos!
          <br />
          Puedes hacerlo de diferentes formas.
        </div>
      </div>
      <div className="cta">
        <Link className="button-colabora" to="/colabora">
          Cónoce cómo colaborar con ICID
        </Link>
      </div>
    </div>
  );
};

const Sponsors = (socios: any) => {
  return (
    <div className="sponsors-footer">
      <div className="social">
        <div className="patrocinadores">
          <span>Financiadores:</span>
          <div className="logos">
            {socios?.socios?.map((socio: any) => {
              if (socio.patrocinadores.tipoDeSocio === "Financiador") {
                return (
                  <div className="logo-image">
                    <img
                      src={socio.patrocinadores.logoPatrocinador.mediaItemUrl}
                      alt={socio.patrocinadores.logoPatrocinador.altText}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="colaboradores">
          <span>Apoyan:</span>
          <div className="logos">
            {socios?.socios?.map((socio: any) => {
              if (socio.patrocinadores.tipoDeSocio === "Apoyan") {
                return (
                  <div className="logo-image">
                    <img
                      src={socio.patrocinadores.logoPatrocinador.mediaItemUrl}
                      alt={socio.patrocinadores.logoPatrocinador.altText}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="entidades">
          <span>Entidades colaboradoras</span>
          <div className="logos">
            {socios?.socios?.map((socio: any) => {
              if (socio.patrocinadores.tipoDeSocio === "Entidad colaboradora") {
                return (
                  <div className="logo-image">
                    <img
                      src={socio.patrocinadores.logoPatrocinador.mediaItemUrl}
                      alt={socio.patrocinadores.logoPatrocinador.altText}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Contacta = () => {
  return (
    <div className="contacta-footer">
      <div className="copy">
        <div className="heading">
          Contacta con ICID para informarte de todas las formas de colaborar.
        </div>
        <p className="paragraph">
          Nos pondremos en contacto contigo para que conozcas mejor las maneras
          de apoyar nuestros proyectos.
        </p>
      </div>
      <a href="mailto:icid@asociacionicid.org">
        <Button className="contacta-footer-button">
          <>
            {" "}
            <Envelope /> Escribenos un correo{" "}
          </>
        </Button>
      </a>
    </div>
  );
};
