import React, { FC } from "react";
import { Projects } from "../../data/types";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import "./styles.scss";
import DondeTrabajamos from "../donde-trabajamos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainContent from "../shared/main-content";
import Spinner from "../shared/spinner";

const Inicio: FC<any> = (props) => {
  const {
    data: { loading },
  } = props;
  const pageContent = props.data.pages
    ? props.data.pages.nodes[0].pagina
    : null;

  const sliderProjects = props.data.pages
    ? props.data.pages.nodes[0].slider_home.slide
    : null;

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    speed: 200,
    // autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <>
          <Slider className="hero-carousel" {...sliderSettings}>
            {sliderProjects?.map((project: Projects) => {
              return (
                <div
                  className="hero-carousel-item"
                  key={project.contenidoSlide.cabecera}
                >
                  <div className="gradient"></div>
                  <img
                    src={project.contenidoSlide.imagen.sourceUrl}
                    srcSet={project.contenidoSlide.imagen.srcSet}
                    alt={project.contenidoSlide.imagen.altText}
                  />
                  <div className="hero-carousel-item-content">
                    {/* <h2 className="hero-carousel-item-country">
                      {project.contenidoSlide.titulo}
                    </h2> */}
                    <p className="hero-carousel-item-heading">
                      {project.contenidoSlide.cabecera}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>

          <main className="main-content">
            <MainContent content={pageContent?.contenido} />
            <DondeTrabajamos />
          </main>
        </>
      )}
    </>
  );
};

export default graphql(gql`
  query GetPageInicio {
    pages(where: { id: 44 }) {
      nodes {
        slider_home {
          slide {
            contenidoSlide {
              cabecera
              imagen {
                altText
                mediaItemUrl
                srcSet
              }
            }
          }
        }
        pagina {
          contenido {
            ... on Page_Pagina_Contenido_Texto {
              fieldGroupName
              texto
            }
            ... on Page_Pagina_Contenido_Cabecera {
              fieldGroupName
              textoCabecera
            }
            ... on Page_Pagina_Contenido_TituloCabecera {
              fieldGroupName
              textoTituloCabecera
            }
            ... on Page_Pagina_Contenido_Separador {
              fieldGroupName
              separador
            }
            ... on Page_Pagina_Contenido_Boton {
              fieldGroupName
              tipoDeBoton
              texto
              link {
                ... on Page {
                  slug
                }
              }
            }
            ... on Page_Pagina_Contenido_TextoEnColumnas {
              fieldGroupName
              columnas {
                ... on Page_Pagina_Contenido_TextoEnColumnas_Columnas_Columna {
                  fieldGroupName
                  textoColumna1
                  textoColumna2
                }
              }
            }
            ... on Page_Pagina_Contenido_Recortes {
              fieldGroupName
              recorte {
                ... on Page_Pagina_Contenido_Recortes_Recorte_Contenido {
                  cabecera
                  parrafo
                  icono {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)(Inicio);
