import React, { useState } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import HeroProject from "../../shared/hero-project";
import Accordion from "../../shared/accordion/index";
import AccordionItem from "../../shared/accordion/accordion-item/accordion-item";
import Slider from "react-slick";
import "./styles.scss";
import { GalleryImage } from "../../../data/types";
import ModalGallery from "../../shared/modal-gallery";
import { SliderNextArrow, SliderPrevArrow } from "../../shared/modal-gallery";
import { nanoid } from "nanoid";
import Spinner from "../../shared/spinner";

const Proyecto = (props: any) => {
  const [viewModal, setViewModal] = useState(false);
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  let { slug } = useParams<any>();
  const project = props?.data?.proyectos?.nodes
    ? props?.data?.proyectos.nodes.filter((project: any) => {
        return project.slug === slug;
      })[0]
    : null;
  const projectsSameSection = props?.data?.proyectos?.nodes
    ? props?.data?.proyectos.nodes.filter((proj: any) => {
        return (
          proj.categories?.edges?.[0]?.node?.name ===
            project?.categories?.edges?.[0]?.node?.name &&
          proj.slug !== project.slug
        );
      })
    : null;

  const sliderImagesSettings = {
    dots: false,
    infinite: true,
    slidesToShow: project?.detalles?.imagenesDelProyecto?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    speed: 200,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    centerMode: true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const sliderProjectsSettings = {
    dots: false,
    infinite: projectsSameSection?.length > 3,
    slidesToShow: projectsSameSection?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    speed: 200,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    centerMode: projectsSameSection?.length > 3,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  const {
    data: { loading },
  } = props;

  const onOpenModal = (images: any, index: number) => {
    setViewModal(true);
    setImages(images);
    setCurrentIndex(index);
  };

  const onCloseModal = () => {
    setViewModal(false);
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className="project">
      <HeroProject content={project?.detalles?.imagenCabecera} />
      <div className="project-content">
        <div className="cabecera ">{project?.detalles.textoCabecera}</div>
        <h3 className="sub-heading">Proyecto</h3>
        <h2 className="title">{project?.title}</h2>
        {project?.detalles?.textoPrincipal && (
          <p>{parse(project?.detalles?.textoPrincipal)}</p>
        )}
        {project?.detalles?.textoSecundario && (
          <Accordion>
            <AccordionItem buttonText="mostrar mas información">
              <p>{parse(project?.detalles.textoSecundario)}</p>
            </AccordionItem>
          </Accordion>
        )}
        {project?.detalles?.imagenesDelProyecto?.length > 0 && (
          <>
            <h3 className="slider-header">Imágenes del proyecto</h3>
            <Slider className="project-carousel" {...sliderImagesSettings}>
              {project?.detalles?.imagenesDelProyecto?.map(
                (item: GalleryImage, index: number) => {
                  return (
                    <div
                      className="img-wrapper"
                      onClick={() =>
                        onOpenModal(
                          project?.detalles?.imagenesDelProyecto,
                          index
                        )
                      }
                    >
                      <img
                        src={item.imagen?.mediaItemUrl}
                        srcSet={item.imagen.srcSet}
                        alt={item.imagen.altText}
                      />
                    </div>
                  );
                }
              )}
            </Slider>
          </>
        )}

        {projectsSameSection?.length > 1 && (
          <>
            <h3 className="slider-header">
              Más proyectos que te pueden interesar
            </h3>
            <Slider
              className="project-carousel related-projects"
              {...sliderProjectsSettings}
            >
              {projectsSameSection?.map((item: any) => {
                return (
                  <div key={nanoid()}>
                    <Link to={`/hacemos/proyectos/${item?.slug}`}>
                      <img
                        src={item?.detalles?.imagenCabecera?.mediaItemUrl}
                        srcSet={item?.detalles?.imagenCabecera?.srcSet}
                        alt={item?.detalles?.imagenCabecera?.altText}
                      />
                      <div className="details">
                        <h4 className="heading">{item.title}</h4>
                        {/* <h4 className="heading">{item.detalles.pais}</h4> */}
                        <p className="paragraph">
                          {item.detalles.textoCabecera}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </>
        )}
      </div>
      {viewModal && (
        <ModalGallery
          images={images}
          currentIndex={currentIndex}
          onClose={onCloseModal}
        />
      )}
    </section>
  );
};

export default graphql(gql`
  query GetProject {
    proyectos(first: 100) {
      nodes {
        detalles {
          pais
          fecha
          textoCabecera
          textoPrincipal
          textoSecundario
          imagenCabecera {
            altText
            mediaItemUrl
            srcSet
          }
          imagenHacemosSeccion {
            altText
            mediaItemUrl
            srcSet
          }
          imagenesDelProyecto {
            imagen {
              mediaItemUrl
              srcSet
              altText
            }
          }
        }
        categories(first: 100) {
          edges {
            node {
              name
            }
          }
        }
        slug
        title
      }
    }
  }
`)(Proyecto);
