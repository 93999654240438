import React from "react";
import { GalleryImage } from "../../../data/types";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { ReactComponent as Chevron } from "../../../assets/chevron.svg";

interface Props {
  images: any;
  currentIndex: number;
  onClose: () => void;
}

export const SliderNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <Chevron />
    </div>
  );
};
export const SliderPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <Chevron />
    </div>
  );
};

const ModalGallery = (props: Props) => {
  const { images, currentIndex, onClose } = props;
  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    speed: 200,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    centerMode: false,
    initialSlide: currentIndex,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };
  return (
    <div className="modal-gallery">
      <div className="close" onClick={onClose}>
        X
      </div>
      <div className="slider-wrapper">
        <Slider className="project-carousel" {...sliderSettings}>
          {images?.map((item: GalleryImage) => {
            return (
              <div className="img-wrapper">
                <img
                  src={item.imagen.mediaItemUrl}
                  srcSet={item.imagen.srcSet}
                  alt={item.imagen.altText}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ModalGallery;
