import React, { FC, ReactChild } from "react";
import "./styles.scss";

interface iProps {
  children: ReactChild;
  className?: string;
  size?: "large" | "medium" | "small";
  type?: "primary" | "secondary" | "tertiary";
  onClick?: () => void;
}

const Button: FC<iProps> = ({
  children,
  className = "",
  size = "medium",
  type = "primary",
  onClick = () => {},
}) => (
  <button className={`button ${className} ${size} ${type}`} onClick={onClick}>
    {children}
  </button>
);

export default Button;
