import React, { FC } from "react";
import "./styles.scss";

interface iProps {
  className?: string;
}

const Separator: FC<iProps> = ({ className = "" }) => (
  <hr className={`separator ${className}`}></hr>
);

export default Separator;
