import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
// import { useParams } from "react-router-dom";
import Hero from "../shared/hero";
import Spinner from "../shared/spinner";
import "./styles.scss";

const ServiciosSeccion = (props: any) => {
  const {
    data: { loading },
  } = props;

  const serviciosSections = props?.data?.pages?.nodes
    ? props?.data?.pages?.nodes?.[0].servicio.servicio
    : null;

  const serviciosPage = props?.data?.pages?.nodes
    ? props?.data?.pages.nodes.map((page: any) => {
        return page.servicios;
      })[0]
    : null;
  if (loading) {
    return <Spinner />;
  }
  return (
    <section className="servicios">
      <Hero content={serviciosPage} />
      {serviciosSections?.map((service: any) => {
        return (
          <div className="hacemos-section">
            <div className="hacemos-section-info">
              <div className="info-content">
                <h2 className="hacemos-section-cabecera" lang="es">
                  {service.cabecera}
                </h2>
                <p>{service.parrafo}</p>
              </div>
            </div>
            <div className="hacemos-section-image">
              <img
                src={service.imagen?.mediaItemUrl}
                srcSet={service.imagen?.srcSet}
                alt={service.imagen?.altText}
              />
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default graphql(gql`
  query getServiciosSeccion {
    pages(where: { id: 29 }) {
      nodes {
        servicios {
          titulo
          parrafo
          imagen {
            srcSet
            sourceUrl
            mediaItemUrl
          }
        }
        servicio {
          servicio {
            cabecera
            imagen {
              altText
              mediaItemUrl
              srcSet
            }
            parrafo
          }
        }
      }
    }
  }
`)(ServiciosSeccion);
