import React, { FC, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/icid-logo.png";
import logoDark from "../../assets/icid-logo-dark.png";
import { ReactComponent as FacebookLogo } from "../../assets/facebook-circular-logo.svg";
import { ReactComponent as TwitterLogo } from "../../assets/twitter.svg";
// import { ReactComponent as Chevron } from "../../assets/chevron.svg";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import "./styles.scss";
import HamburgerIcon from "./hamburger-icon/index";
import Spinner from "../shared/spinner";

interface iProps {
  data: {
    loading: boolean;
    pages: any;
  };
  location: any;
}

const Nav: FC<iProps & any> = (props) => {
  const {
    data: { loading, pages, categories },
    location,
  } = props;

  const hacemosSubItems = categories?.nodes
    ? categories.nodes
        .sort((a: any, b: any) => Number(b.count) - Number(a.count))
        .filter(
          (cat: any) =>
            cat.categoryId !== 28 &&
            cat.categoryId !== 31 &&
            cat.categoryId !== 32
        )
    : null;

  hacemosSubItems?.unshift(
    categories?.nodes?.filter((cat: any) => cat.categoryId === 31)[0]
  );
  hacemosSubItems?.unshift(
    categories?.nodes?.filter((cat: any) => cat.categoryId === 28)[0]
  );
  hacemosSubItems?.unshift(
    categories?.nodes?.filter((cat: any) => cat.categoryId === 32)[0]
  );

  const [navToggle, setNavToggle] = useState(false);
  const [subnavToggle] = useState(false);
  const [section, setSection] = useState("");
  const [navClassName, setNavClassName] = useState(
    location.pathname === "/colabora" ||
      location.pathname === "/materiales" ||
      location.pathname === "/politica-privacidad"
      ? "nav-secondary"
      : ""
  );

  useEffect(() => {
    if (
      location.pathname === "/colabora" ||
      location.pathname === "/materiales" ||
      location.pathname === "/politica-privacidad"
    ) {
      setNavClassName("nav-secondary");
    } else {
      setNavClassName("");
    }
  }, [location]);

  const onToggleNav = () => {
    setNavToggle((navOpen) => !navOpen);
  };
  const onClickNavItem = () => {
    setNavToggle(false);
  };
  const onToggleSubNav = (clickedSection: string) => {
    setSection(clickedSection);
  };

  if (loading) {
    return <Spinner />;
  }

  if (pages?.items) {
    return (
      <>
        <Link to={"/"} className={`logo-movil-wrapper ${navClassName}`}>
          <img src={logoDark} alt="logo icid" className="logo-movil" />
          <img src={logo} alt="logo icid" className="logo-movil" />
        </Link>
        <HamburgerIcon onClick={onToggleNav} open={navToggle} />
        <nav
          className={`main-navigation ${
            navToggle ? "nav-open" : ""
          } ${navClassName}`}
        >
          <div className={`logo ${navClassName}`}>
            <Link to={"/"}>
              <img src={logo} alt="logo icid" />
              <img src={logoDark} alt="logo icid" />
            </Link>
          </div>
          <div className="nav-items">
            {pages?.items?.map((item: any) => {
              return (
                <div key={item.page.slug}>
                  {item.page.slug !== "hacemos" &&
                    item.page.slug !== "somos" &&
                    item.page.slug !== "politica-privacidad" && (
                      <Link
                        onClick={onClickNavItem}
                        key={item.page.slug}
                        to={`/${item.page.slug}`}
                        className={`nav-item ${item.page.slug} ${
                          location.pathname === `/${item.page.slug}`
                            ? "active"
                            : ""
                        }`}
                      >
                        {item.page.title}
                      </Link>
                    )}
                  {item.page.slug === "hacemos" && (
                    <div
                      onClick={onClickNavItem}
                      key={item.page.slug}
                      className={`with-subnav ${
                        subnavToggle && section === item.page.title
                          ? "subnav-open"
                          : ""
                      }`}
                    >
                      <Link
                        onClick={() => onToggleSubNav(item.page.title)}
                        key={item.page.slug}
                        to={`/${item.page.slug}`}
                        className={`nav-item ${item.page.slug} ${
                          location.pathname === `/${item.page.slug}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <span onClick={onClickNavItem}>{item.page.title}</span>
                        {/* <div className="chevron-icon">
                          <Chevron />
                        </div> */}
                        <ul className="subnav hacemos-subnav">
                          {hacemosSubItems?.map((cat: any) => {
                            return (
                              <Link
                                onClick={onClickNavItem}
                                key={cat.slug}
                                to={`/hacemos/seccion/${cat.slug}`}
                                className="nav-sub-item"
                              >
                                {cat.name}
                              </Link>
                            );
                          })}
                        </ul>
                      </Link>
                    </div>
                  )}
                  {item.page.slug === "somos" && (
                    <div
                      onClick={onClickNavItem}
                      key={item.page.slug}
                      className={`with-subnav  ${
                        subnavToggle && section === item.page.title
                          ? "subnav-open"
                          : ""
                      }`}
                    >
                      <Link
                        onClick={() => onToggleSubNav(item.page.title)}
                        key={item.page.slug}
                        to={`/${item.page.slug}`}
                        className={`nav-item ${item.page.slug} ${
                          location.pathname === `/${item.page.slug}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <span onClick={onClickNavItem}>{item.page.title}</span>
                        {/* <div className="chevron-icon">
                          <Chevron />
                        </div> */}
                        <ul className="subnav somos-subnav">
                          <Link
                            onClick={onClickNavItem}
                            to={`/somos/documentacion-institucional`}
                            className="nav-sub-item"
                          >
                            Documentación institucional
                          </Link>
                        </ul>
                      </Link>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="social-media">
              <a
                href="https://www.facebook.com/AsociacionIcid"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo />
              </a>
              <a
                href="https://twitter.com/AsociacionICID"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterLogo />
              </a>
            </div>
          </div>
          <div className="details">
            <a href="mailto:icid@asociacionicid.org">Contacto</a>
            <Link onClick={onToggleNav} to={"/politica-privacidad"}>
              Politica de privacidad y cookies
            </Link>
            <span>ICID &copy; {new Date().getFullYear()}</span>
          </div>
        </nav>
      </>
    );
  }

  return <div>Error loading navigation (No pages found)</div>;
};

export default graphql(gql`
  query GetMainNavigation {
    categories {
      nodes {
        name
        slug
        categoryId
        count
      }
    }

    pages(first: 10, where: { orderby: { field: MENU_ORDER, order: ASC } }) {
      items: edges {
        page: node {
          slug
          title
        }
      }
    }
  }
`)(withRouter(Nav));
