import React, { FC, ReactChild } from "react";
import "./styles.scss";

interface iProps {
  children: ReactChild;
  className?: string;
  align?: "left" | "center" | "right";
}

const MainHeadingTop: FC<iProps> = ({
  children,
  className = "",
  align = "left",
}) => <h3 className={`main-heading-top ${className} ${align}`}>{children}</h3>;

export default MainHeadingTop;
