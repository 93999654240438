import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Nav from "./components/nav";
import Inicio from "./components/inicio";
import Somos from "./components/somos";
import Servicios from "./components/servicios";
import Hacemos from "./components/hacemos";
import Colabora from "./components/colabora";
import Proyecto from "./components/hacemos/proyecto";
import Etiquetas from "./components/hacemos/etiquetas";
import PoliticaPrivacidad from "./components/politica-privacidad";
import hacemosSeccion from "./components/hacemos/hacemos-seccion";
import Materiales from "./components/materiales/index";
import Footer from "./components/footer";
import ScrollToTop from "./components/shared/scroll-to-top";
import "./app.scss";
import CookieBanner from "./components/shared/cookies-banner/cookies-banner";

const Page404 = ({ location }: any) => (
  <div className="main-content not-found">
    <h2>
      Ruta no encontrada <code>{location.pathname}</code>
    </h2>
    <Link to="/">Inicio</Link>
  </div>
);

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState({
    cookiesAccepted: !!localStorage.getItem("icidCookiesAccepted") || false,
  });

  const [showCookiesBanner, setShowCookiesBanner] = useState(
    localStorage.getItem("icidShowCookiesBanner") !== null
      ? !!localStorage.getItem("icidShowCookiesBanner")
      : true
  );

  useEffect(() => {
    localStorage.setItem("icidCookiesAccepted", "");
  }, []);
  const onClickCookieOptions = (option: boolean) => {
    if (showCookiesBanner) {
      setShowCookiesBanner(false);
    }
    setCookiesAccepted({ cookiesAccepted: option });
    localStorage.setItem("icidCookiesAccepted", option ? "true" : "");
    localStorage.setItem("icidShowCookiesBanner", "");
  };

  const onClickOptions = { onClickCookieOptions: onClickCookieOptions };
  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <ScrollToTop />
        <Nav />
        <Switch>
          <Route path="/" component={Inicio} exact />
          <Route path="/inicio" component={Inicio} />
          <Route exact path="/somos" component={Somos} />
          <Route path="/somos/:documentacion" component={Somos} />
          <Route exact path="/hacemos/" component={Hacemos} />
          <Route
            exact
            path="/hacemos/seccion/:section"
            component={hacemosSeccion}
          />
          <Route path="/hacemos/proyectos/:slug" component={Proyecto} />
          <Route path="/hacemos/etiquetas/:name" component={Etiquetas} />
          <Route exact path="/servicios" component={Servicios} />
          <Route
            exact
            path="/materiales"
            render={() => {
              console.log("test");
              // this can be inproved as I needed this destructuring here due to a TS error

              return <Materiales {...cookiesAccepted} {...onClickOptions} />;
            }}
          />
          <Route path="/colabora" component={Colabora} />
          <Route path="/politica-privacidad" component={PoliticaPrivacidad} />
          <Route component={Page404} />
        </Switch>
        <Footer />
        {showCookiesBanner && (
          <CookieBanner onClickOption={onClickCookieOptions} />
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
