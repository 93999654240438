import React, { FC } from "react";
import "./styles.scss";
import { SocioLocal } from "../../data/types";

interface iProps {
  className?: string;
  paragraph: string;
  sociosLocales: SocioLocal[];
}

const SociosLocales: FC<iProps> = ({
  className = "",
  sociosLocales,
  paragraph,
}) => (
  <section className={`socios-locales ${className}`}>
    <p>{paragraph}</p>
    {sociosLocales.map((socio: SocioLocal) => (
      <div className="socio">
        <div className="socio-titulo">{socio.pais}</div>
        <ul className="socio-links">
          {socio.enlaces.map((enlace) => {
            return (
              <li>
                <a href={enlace.enlace}>{enlace.texto}</a>
              </li>
            );
          })}
        </ul>
      </div>
    ))}
  </section>
);

export default SociosLocales;
