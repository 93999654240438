import React, { FC } from "react";
import "./styles.scss";

interface iProps {
  className?: string;
  content: any;
  heading?: string;
}

const HeroProject: FC<iProps> = ({
  className = "",
  content,
  heading = null,
}) => {
  const img = content?.imagen || content;
  return (
    <div className={`hero-project ${className}`}>
      <div className="overlay"></div>
      {heading && <h1 className="hero-project-heading">{heading}</h1>}
      <img srcSet={img?.srcSet} src={img?.srcUrl} alt={img?.altText} />
    </div>
  );
};

export default HeroProject;
