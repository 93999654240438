import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Link, useParams } from "react-router-dom";
import "./styles.scss";
import Spinner from "../../shared/spinner";
import Button from "../../shared/button";
import HeroProject from "../../shared/hero-project";

const Etiquetas = (props: any) => {
  let { name } = useParams<any>();
  const {
    data: { loading },
  } = props;

  const proyectos = props?.data?.proyectos?.nodes
    ? props?.data?.proyectos.nodes.filter((proj: any) => {
        return proj.tags?.edges
          ?.map((tag: any) => tag.node.slug)
          ?.some((tag: any) => tag === name);
      })
    : null;

  const cabecera = props.data.pages
    ? {
        imagen: props.data.pages?.nodes?.[0]?.cabecera.cabecera.imagen,
        titulo:
          props?.data?.pages?.nodes?.[0]?.hacemos_section?.seccion?.titulo
            ?.name,
        parrafo: "",
      }
    : null;

  const heading = proyectos?.[0].tags?.edges?.filter(
    (tag: any) => tag.node.slug === name
  )[0];

  if (loading) {
    return <Spinner />;
  }

  return (
    <section className="etiquetas">
      {/* <Hero content={cabecera} /> */}
      <HeroProject content={cabecera?.imagen} heading={heading?.node?.name} />
      <div className="hacemos-projects">
        {proyectos?.map((project: any) => {
          return (
            <div className="hacemos-project" key={project.slug}>
              <div className="project-image">
                <img
                  src={project.detalles?.imagenHacemosSeccion?.mediaItemUrl}
                  srcSet={project.detalles?.imageHacemosSeccion?.srcSet}
                  alt={project.detalles?.imagenHacemosSeccion?.altText}
                />
              </div>
              <div className="project-details">
                <p className="title">{project.detalles.textoCabecera}</p>

                <Link
                  className="ver-proyecto-button"
                  to={`/hacemos/proyectos/${project.slug}`}
                >
                  <Button size="medium" type="secondary">
                    Ver proyecto
                  </Button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default graphql(gql`
  query getHacemos {
    pages(where: { id: 37 }) {
      nodes {
        cabecera {
          cabecera {
            titulo
            parrafo
            imagen {
              altText
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
    proyectos(first: 100) {
      nodes {
        detalles {
          pais
          fecha
          textoCabecera
          textoPrincipal
          textoSecundario
          imagenCabecera {
            altText
            mediaItemUrl
            srcSet
          }
          imagenHacemosSeccion {
            altText
            mediaItemUrl
            srcSet
          }
          imagenesDelProyecto {
            imagen {
              mediaItemUrl
              srcSet
              altText
            }
          }
        }
        categories(first: 100) {
          edges {
            node {
              name
              slug
            }
          }
        }
        slug
        title
        tags {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
    }
  }
`)(Etiquetas);
