import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import possibleTypes from "./possibleTypes.json";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes as any,
});

const hostname = document.location.hostname;
const client = new ApolloClient({
  uri: `https://${hostname}/icid/graphql`,
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
