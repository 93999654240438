import React, { FC } from "react";
import "./styles.scss";
import { SnippetsInicio } from "../../../data/types";
import { nanoid } from "nanoid";

interface iProps {
  snippets: SnippetsInicio[];
}

const Snippets: FC<iProps> = ({ snippets }) => {
  return (
    <div
      className={`snippets ${
        snippets.length % 3 === 0 ? "three-columns" : "two-columns"
      } `}
    >
      {snippets.map((snippet: any) => {
        return (
          <div className="snippet" key={nanoid()}>
            <div className="icono">
              <img
                src={snippet?.icono?.mediaItemUrl || snippet?.icono?.sourceUrl}
                alt={snippet.cabecera}
              />
            </div>
            <h3 className="cabecera">{snippet.cabecera}</h3>
            <p className="parrafo hide-small">{snippet.parrafo}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Snippets;
