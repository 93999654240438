import React, { FC } from "react";
import { Link } from "react-router-dom";
import Button from "../button";
import "./styles.scss";

const CookieBanner: FC<{
  onClickOption: (option: boolean) => void;
}> = ({ onClickOption }) => {
  return (
    <div className="cookie-banner">
      <div className="wrapper">
        <div className="copy">
          <p>
            Éste sitio web usa cookies. Si desea acceder a todo el contenido que
            ofrecemos debe aceptarlas, en caso contrario, algunos contenidos no
            estarán disponibles.
          </p>
          <p>
            Puede leer más sobre el uso de cookies en nuestra{" "}
            <Link to={"/politica-privacidad"}>
              política de privacidad y cookies
            </Link>
            .{" "}
          </p>
        </div>
        <div className="actions">
          <Button onClick={() => onClickOption(true)}>Aceptar</Button>
          <Button onClick={() => onClickOption(false)}>Rechazar</Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
